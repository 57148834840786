import React, { createRef, ReactNode, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavItem, Menu as M } from '@groma-dev/navigation';
import { classNames } from '@groma-dev/blueprint';
import { useLocation } from 'react-router-dom';
import { HeaderMenu } from '../Header';
import { Menu } from '../menu/Menu';
import styles from './MenuNavItem.module.scss';

export interface MenuNavItemProps {
  children: ReactNode;
  name: HeaderMenu;
  subRoutes?: string[];
}

export const MenuNavItem = observer((props: MenuNavItemProps) => {
  const { children, name, subRoutes } = props;
  const navItemRef = createRef<HTMLButtonElement>();
  const navMenuRef = createRef<HTMLDivElement>();
  const { pathname } = useLocation();
  let timeout: NodeJS.Timeout;
  const [showMenu, setShowMenu] = useState(false);

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setShowMenu(false);
    }
  };

  const hasActiveRoute = () => {
    return subRoutes?.find((r: string) => pathname === r);
  };

  const handleShowMenu = () => {
    if (timeout) clearTimeout(timeout);
    setTimeout(() => setShowMenu(true), 150);
  };

  const handleHideMenu = (event: React.MouseEvent & React.FocusEvent<any>) => {
    if (
      event.relatedTarget instanceof Window ||
      (!navItemRef.current?.contains(event.relatedTarget) && !navMenuRef.current?.contains(event.relatedTarget))
    ) {
      timeout = setTimeout(() => {
        setShowMenu(false);
      }, 225);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className={classNames(styles.navContainer)}>
      <NavItem
        as="button"
        ref={navItemRef}
        className={classNames(styles.navItem, hasActiveRoute() && styles.active)}
        onBlur={handleHideMenu}
        onClick={handleShowMenu}
        onMouseEnter={handleShowMenu}
        onMouseLeave={handleHideMenu}
        testId={`${name}NavItem`}>
        {children}
      </NavItem>
      <M
        ref={navMenuRef}
        role="presentation"
        active={showMenu}
        onMouseLeave={handleHideMenu}
        onBlur={handleHideMenu}
        onMouseEnter={handleShowMenu}
        className={classNames(styles.menu, showMenu && 'active')}
        testId={`${name}Menu`}>
        <Menu section={name} />
      </M>
    </div>
  );
});
