import { createStyleSheet, GlobalThemeInterface, vh } from '@groma-dev/blueprint';

export const getLoadingStyles = createStyleSheet(
  (theme: GlobalThemeInterface) => ({
    mainContainer: {
      alignItems: 'center',
      backgroundColor: theme.colors.$background100,
      display: 'flex',
      height: (isPage: { isPage: boolean }) => (isPage ? `calc(${vh()} - 80px)` : '100%'),
      justifyContent: 'center',
      minHeight: (isPage: { isPage: boolean }) => (isPage ? `calc(${vh()} - 80px)` : '100%'),
      width: '100%',
    },
  }),
  { name: 'Loading' }
);
