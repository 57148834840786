import get from 'lodash/get';
import { constructUrlWithParams, LocaleCode } from '@groma-dev/scaffold';
import { flattenLocales as FL } from '@groma-dev/blueprint';
import { AppConfig } from '../../core/AppConfig';

export const flattenLocales = FL;

export const getRawLocaleValue = (lang: string, id: string) => {
  const { locales } = AppConfig.Settings.Localization;
  // handles older browsers where language is lowercase
  // and fallback if locale is not found by lang
  const locale = locales[lang.toLowerCase()] ?? locales[LocaleCode.en_us];

  return get(locale, id);
};

export const scrollToTop = () => {
  // eslint-disable-next-line no-multi-assign
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export const jumpToAnchorRef = () => {
  let counter = 0;

  const interval = setInterval(() => {
    if (counter === 10) clearInterval(interval);

    findElement();
    counter++;
  }, 500);

  const findElement = () => {
    const { hash } = window.location;
    const el = document.getElementById(hash?.split('#')[1]);

    if (el) {
      if (interval) clearInterval(interval);
      el.scrollIntoView(true);
    }
  };

  findElement();
};

export const buildUrlWithParams = (url: string) => {
  const { allowList } = AppConfig.Settings.Routing.searchParams;
  return constructUrlWithParams(url, { persistList: allowList });
};

export const constructImageUrl = (url: string, options?: any): string => {
  let params;
  if (options) {
    params = Object.entries(options)
      .map(([key, value]: any) => `${key}=${value}`)
      .join('&');
  }

  return `${url}${params ? `?${params}` : ''}`;
};

export const isProduction = () => {
  return process.env.NODE_ENV === 'production';
};
