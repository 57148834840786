export const AppRoutes = {
  About: '/about',
  AdvisoryBoard: '/about/advisory-board',
  Auth: '/auth',
  BoardOfDirectors: '/about/board-of-directors',
  Careers: '/careers',
  Contact: '/contact',
  FAQ: '/faq',
  Home: '/',
  Listings: '/listings',
  Partnerships: '/partner',
  Press: '/press',
  PressRelease: '/press/:slug',
  Privacy: '/privacy',
  Properties: '/properties',
  Realty: '/realty',
  Terms: '/terms-of-use',
  TrashAndRecycling: '/faq/tenants/trash-and-recycling',
};
