import {
  createStyleSheet,
  GlobalThemeInterface,
  headerHeight,
  maxContainerWidth,
  vh,
  flexMixin,
} from '@groma-dev/blueprint';

const careersImageWebP = require('../assets/careersImage.webp');
const careersImage = require('../assets/careersImage.png');

export const getAboutStyles = createStyleSheet(
  (theme: GlobalThemeInterface) => ({
    mainContainer: {
      backgroundColor: theme.colors.$background100,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: `calc(${vh()} - ${headerHeight}px)`,
    },
    title: {
      ...theme.typography.$textPrimary550,
      color: theme.colors.$tertiary100,
      marginBottom: theme.spacing.$s7,
    },
    subTitle: {
      ...theme.typography.$textPrimary200,
      color: theme.colors.$tertiary100,
      // DIFF lineHeight: '30px',
      whiteSpace: 'pre-wrap',
    },
    navContainer: {
      borderBottom: `1px solid ${theme.colors.$neutral200}`,
      display: 'flex',
      width: '100%',
    },
    navWrapper: {
      display: 'flex',
      maxWidth: `${maxContainerWidth}px`,
      width: '100%',
      margin: '0 auto',
      gap: theme.spacing.$s8,
      padding: `${theme.spacing.$s4} ${theme.spacing.$s5} 0`,
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    navLink: {
      '&.Link-local': {
        '& a': {
          ...theme.typography.$textPrimary200,
          borderBottom: '3px solid transparent',
          padding: `${theme.spacing.$s4} 0`,
          textDecoration: 'none',
        },
      },
      '&$active': {
        '&.Link-local': {
          '& a': {
            ...theme.typography.$textPrimary250,
            color: theme.colors.$secondary300,
            padding: `${theme.spacing.$s4} 0`,
            borderBottomColor: theme.colors.$secondary300,
          },
        },
      },
    },
    careerContainer: {
      backgroundImage: `url(${careersImageWebP}), url(${careersImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      borderRadius: '4px',
      height: '100%',
      width: '100%',
    },
    careerWrapper: {
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      padding: `${theme.spacing.$s7} ${theme.spacing.$s6}`,
      width: '100%',
    },
    careerTitle: {
      ...theme.typography.$textPrimary550,
      color: theme.colors.$neutral100,
      marginBottom: theme.spacing.$s4,
    },
    careerContent: {
      ...theme.typography.$textPrimary200,
      color: theme.colors.$neutral100,
      // DIFF lineHeight: '30px',
      width: '50%',
      whiteSpace: 'pre-wrap',
      marginBottom: theme.spacing.$s4,
    },
    careerLink: {
      '& a': {
        color: theme.colors.$neutral100,
        padding: '0',
        '&:hover, &:focus': {
          color: theme.colors.$neutral100,
        },
        '&:active': {
          color: theme.colors.$neutral100,
        },
        '&:visited': {
          color: theme.colors.$neutral100,
          '&:hover, &:focus': {
            color: theme.colors.$neutral100,
          },
          '&:active': {
            color: theme.colors.$neutral100,
          },
        },
      },
    },
    section: {
      '&.Section-local': {
        ...flexMixin('column'),
      },
    },
    active: {},
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
      section: {
        '&.Section-local': {
          ...flexMixin('column'),
          padding: theme.spacing.$s3,
        },
      },
      navWrapper: {
        gap: theme.spacing.$s5,
      },
      navLink: {
        '&.Link-local': {
          '& a': {
            ...theme.typography.$textPrimary100,
            borderBottom: '3px solid transparent',
            padding: `${theme.spacing.$s4} 0`,
            textDecoration: 'none',
          },
        },
        '&$active': {
          '&.Link-local': {
            '& a': {
              ...theme.typography.$textPrimary150,
              color: theme.colors.$secondary300,
              padding: `${theme.spacing.$s4} 0`,
              borderBottomColor: theme.colors.$secondary300,
            },
          },
        },
      },
      title: {
        ...theme.typography.$textPrimary450,
        color: theme.colors.$tertiary100,
        marginBottom: theme.spacing.$s4,
      },
      subTitle: {
        ...theme.typography.$textPrimary100,
        color: theme.colors.$tertiary100,
      },
      careerTitle: {
        ...theme.typography.$textPrimary550,
        color: theme.colors.$neutral100,
        marginBottom: theme.spacing.$s4,
      },
      careerContent: {
        ...theme.typography.$textPrimary100,
        color: theme.colors.$neutral100,
        width: '100%',
        marginBottom: theme.spacing.$s4,
      },
    },
  }),
  { name: 'About' }
);
