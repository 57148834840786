import isNil from 'lodash/isNil';

import { observable, action, runInAction, makeObservable } from 'mobx';
import { APIClient as ScaffoldClient, FetchUserResponse, SignOutUserResponse, User } from '@groma-dev/scaffold';
import { APIClient } from '../apiclient/APIClient';
import {
  ContactUsRequest,
  ContactUsResponse,
  GromaTeamResponse,
  AdvisoryBoardResponse,
  RealtyTeamResponse,
  BoardOfDirectorsResponse,
  FetchFAQItemsResponse,
  FetchGromaREITStatsResponse,
} from '../apiclient/APIClientInterface';
import { AppConfig } from '../AppConfig';
import {
  AdvisoryBoardState,
  CareersState,
  FAQState,
  GromaREITStatState,
  GromaTeamState,
  PressReleasesState,
  RealtyTeamState,
  ScaffoldClientHeaders,
  UserStateInterface,
} from './DataStoreInterface';

export default class DataStore {
  /**
   * CLASS VARIABLES
   */
  private static instance: any = null;

  /**
   * MOBX OBSERVABLES
   */
  @observable
  advisoryBoardState: AdvisoryBoardState = {
    loadingMembers: false,
  };

  @observable
  boardOfDirectorsState: AdvisoryBoardState = {
    loadingMembers: false,
  };

  @observable
  careersState: CareersState = {
    loadingDepartments: false,
    departments: undefined,
  };

  @observable
  gromaTeamState: GromaTeamState = {
    loadingTeam: false,
  };

  @observable
  pressReleasesState: PressReleasesState = {
    loadingPress: false,
    loadingPressRelease: false,
  };

  @observable
  realtyTeamState: RealtyTeamState = {
    loadingTeam: false,
  };

  @observable
  faqItemState: FAQState = {
    loadingFAQItems: false,
  };

  @observable
  gromaREITStatState: GromaREITStatState = {
    loadingStats: false,
  };

  @observable
  userState: UserStateInterface = {
    fetchingUser: false,
    user: undefined,
  };

  constructor() {
    if (isNil(DataStore.instance)) {
      DataStore.instance = this;
    }

    makeObservable(this);

    // DataStore is a singleton class
    // eslint-disable-next-line no-constructor-return
    return DataStore.instance;
  }

  /**
   * MOBX ACTIONS
   */
  @action
  fetchUser = async (): Promise<FetchUserResponse> => {
    this.setFetchingUser(true);
    const { baseUrl } = AppConfig.Settings.Server;
    const response: FetchUserResponse = await ScaffoldClient.fetchUser({
      baseURL: baseUrl,
      headers: ScaffoldClientHeaders,
    });

    if (response.success) this.setUser(response.user);

    this.setFetchingUser(false);

    return response;
  };

  @action
  signOutUser = async (): Promise<SignOutUserResponse> => {
    const { baseUrl } = AppConfig.Settings.Server;
    const response = await ScaffoldClient.signOutUser({
      baseURL: baseUrl,
      headers: ScaffoldClientHeaders,
    });
    return response;
  };

  @action
  getJobDepartments = async (abortController: AbortController) => {
    this.careersState.loadingDepartments = true;
    this.careersState.departments = undefined;

    const response = await APIClient.getJobDepartments({}, abortController);
    if (response.success) {
      runInAction(() => {
        this.careersState.loadingDepartments = false;
        this.careersState.departments = response.departments;
      });
    }

    return response;
  };

  @action
  getPress = async (abortController: AbortController) => {
    const { baseUrl } = AppConfig.Pages.Press.options;
    this.setLoadingPress(true);

    const response = await APIClient.getPress({ baseUrl }, abortController);
    if (response.success) this.setLoadingPress(false);

    return response;
  };

  @action
  getPressRelease = async (slug: string, abortController: AbortController) => {
    const { baseUrl } = AppConfig.Pages.Press.options;
    this.setLoadingPressRelease(true);

    const response = await APIClient.getPressRelease({ baseUrl, slug }, abortController);
    if (response) this.setLoadingPressRelease(false);

    return response;
  };

  @action
  getGromaTeam = async (abortController: AbortController): Promise<GromaTeamResponse> => {
    const { baseUrl } = AppConfig.Pages.About.options;

    this.setLoadingGromaTeam(true);

    const response = await APIClient.getGromaTeam({ baseUrl }, abortController);
    if (response) this.setLoadingGromaTeam(false);

    return response;
  };

  @action
  getAdvisoryBoard = async (abortController: AbortController): Promise<AdvisoryBoardResponse> => {
    const { baseUrl } = AppConfig.Pages.About.options;

    this.setLoadingAdvisoryBoard(true);

    const response = await APIClient.getAdvisoryBoard({ baseUrl }, abortController);
    if (response) this.setLoadingAdvisoryBoard(false);

    return response;
  };

  @action
  getRealtyTeam = async (abortController: AbortController): Promise<RealtyTeamResponse> => {
    const { baseUrl } = AppConfig.Pages.About.options;

    this.setLoadingRealtyTeam(true);

    const response = await APIClient.getRealtyTeam({ baseUrl }, abortController);
    if (response) this.setLoadingRealtyTeam(false);

    return response;
  };

  @action
  getBoardOfDirectors = async (abortController: AbortController): Promise<BoardOfDirectorsResponse> => {
    const { baseUrl } = AppConfig.Pages.About.options;

    this.setLoadingBoardOfDirectors(true);

    const response = await APIClient.getBoardOfDirectors({ baseUrl }, abortController);
    if (response) this.setLoadingBoardOfDirectors(false);

    return response;
  };

  @action
  getFAQItems = async (abortController: AbortController): Promise<FetchFAQItemsResponse> => {
    const { baseUrl } = AppConfig.Pages.FAQ.options;

    this.setLoadingFAQItems(true);

    const response = await APIClient.getFAQItems({ baseUrl }, abortController);
    if (response) this.setLoadingFAQItems(false);

    return response;
  };

  @action
  getGromaREITStats = async (abortController: AbortController): Promise<FetchGromaREITStatsResponse> => {
    const { baseUrl } = AppConfig.Pages.FAQ.options;

    this.setFetchingGromaREITStats(true);

    const response = await APIClient.getGromaREITStats({ baseUrl }, abortController);
    if (response) this.setFetchingGromaREITStats(false);

    return response;
  };

  @action
  sendContactUsMessage = async (data: ContactUsRequest): Promise<ContactUsResponse> => {
    const response = await APIClient.sendContactUsMessage(data);
    return response;
  };

  @action
  setFetchingUser = (value: boolean) => {
    this.userState.fetchingUser = value;
  };

  @action
  setUser = (user?: User) => {
    this.userState.user = user;
  };

  @action
  setLoadingPress = (value: boolean) => {
    this.pressReleasesState.loadingPress = value;
  };

  @action
  setLoadingPressRelease = (value: boolean) => {
    this.pressReleasesState.loadingPressRelease = value;
  };

  @action
  setLoadingGromaTeam = (value: boolean) => {
    this.gromaTeamState.loadingTeam = value;
  };

  @action
  setLoadingAdvisoryBoard = (value: boolean) => {
    this.advisoryBoardState.loadingMembers = value;
  };

  @action
  setLoadingRealtyTeam = (value: boolean) => {
    this.realtyTeamState.loadingTeam = value;
  };

  @action
  setLoadingBoardOfDirectors = (value: boolean) => {
    this.boardOfDirectorsState.loadingMembers = value;
  };

  @action
  setLoadingFAQItems = (value: boolean) => {
    this.faqItemState.loadingFAQItems = value;
  };

  @action
  setFetchingGromaREITStats = (value: boolean) => {
    this.gromaREITStatState.loadingStats = value;
  };
}
