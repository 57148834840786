import { User } from '@groma-dev/scaffold';
import { Department } from '../models';
import { AppConfig } from '../AppConfig';

export const ScaffoldClientHeaders = {
  'Content-Type': `vnd.groma.${AppConfig.Settings.Server.apiVersion}+json`,
  Accept: `vnd.groma.${AppConfig.Settings.Server.apiVersion}+json`,
};

export interface AdvisoryBoardState {
  loadingMembers: boolean;
}

export interface BoardOfDirectorsState {
  loadingMembers: boolean;
}

export interface CareersState {
  loadingDepartments: boolean;
  departments?: Department[];
}

export interface GromaTeamState {
  loadingTeam: boolean;
}

export interface PressReleasesState {
  loadingPress: boolean;
  loadingPressRelease: boolean;
}

export interface RealtyTeamState {
  loadingTeam: boolean;
}

export interface FAQState {
  loadingFAQItems: boolean;
}

export interface UserStateInterface {
  fetchingUser: boolean;
  user?: User;
}

export interface GromaREITStatState {
  loadingStats: boolean;
}
