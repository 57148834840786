import React, { createRef, Suspense, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  BypassBlock,
  classNames,
  extendTheme,
  getTestIdAttribute,
  globalDarkTheme,
  headerHeight,
  Helmet,
  ToastProvider,
  withTheme,
  useTheme,
  ModalProvider,
  TooltipProvider,
} from '@groma-dev/blueprint';
import { Route, Routes, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { ComponentFactory } from '../../ComponentFactory';
import { AppConfig } from '../../../core/AppConfig';
import { useAppStore } from '../../providers/StoresProvider';
import { FirebaseEvents } from '../../utils/Constants';
import { jumpToAnchorRef, scrollToTop } from '../../utils/WebHelper';
import { AppRoutes } from '../../../core/routes';
import PressRelease from '../../pages/pressrelease/PressRelease';
import AuthRouter from '../authrouter/AuthRouter';
import { AboutView } from '../../pages/about/About';
import { getAppStyles } from './styles/App.styles';

const About = React.lazy(() => ComponentFactory.AboutAsync);
const Auth = React.lazy(() => ComponentFactory.AuthAsync);
const Careers = React.lazy(() => ComponentFactory.CareersAsync);
const Contact = React.lazy(() => ComponentFactory.ContactAsync);
const ErrorPage = React.lazy(() => ComponentFactory.ErrorPageAsync);
const FAQ = React.lazy(() => ComponentFactory.FAQAsync);
const Home = React.lazy(() => ComponentFactory.HomeAsync);
const Partnerships = React.lazy(() => ComponentFactory.PartnershipsAsync);
const Press = React.lazy(() => ComponentFactory.PressAsync);
const Privacy = React.lazy(() => ComponentFactory.PrivacyAsync);
const Realty = React.lazy(() => ComponentFactory.RealtyAsync);
const Terms = React.lazy(() => ComponentFactory.TermsAsync);
const TrashAndRecycling = React.lazy(() => ComponentFactory.TrashAndRecyclingAsync);

export interface AppProps {}

const AppContent = observer(() => {
  const theme = useTheme();
  const styles = getAppStyles();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  const showHeaderAndFooter = pathname !== AppRoutes.Auth;

  const partialDarkTheme = {
    colors: {
      $background100: theme.colors.$primary400,
      $background200: theme.colors.$primary500,
      $content100: theme.colors.$white,
      $accent200: '#B4E9D0', // TODO: Determine if this color is needed to be changed in Blueprint
    },
  };

  const customDarkTheme = extendTheme(globalDarkTheme, partialDarkTheme);

  return (
    <>
      {showHeaderAndFooter && <ComponentFactory.Header />}
      <div id="mainContent" className={styles.mainWrapper}>
        <ToastProvider offsetTop={headerHeight}>
          {/* ErrorBoundry catches and page errors */}
          <ComponentFactory.ErrorBoundary isFatal={false}>
            <Suspense fallback={<ComponentFactory.Loading isPage />}>
              <Routes>
                <Route path={AppRoutes.About} element={<About view={AboutView.Team} />} />
                <Route path={AppRoutes.AdvisoryBoard} element={<About view={AboutView.AdvisoryBoard} />} />
                <Route path={AppRoutes.Auth} element={<Auth />} />
                <Route path={AppRoutes.BoardOfDirectors} element={<About view={AboutView.BoardOfDirectors} />} />
                <Route path={AppRoutes.Careers} element={<Careers />} />
                <Route path={AppRoutes.Contact} element={<Contact />} />
                <Route path={AppRoutes.FAQ}>
                  <Route index element={withTheme(<FAQ />, globalDarkTheme)} />
                  <Route path={AppRoutes.TrashAndRecycling} element={<TrashAndRecycling />} />
                </Route>
                <Route path={AppRoutes.Home} element={withTheme(<Home />, customDarkTheme)} />
                {/* return NULL given below TenantTurner Widget will be visible */}
                <Route
                  path={AppRoutes.Listings}
                  element={
                    <Helmet>
                      <title>
                        {formatMessage({
                          id: 'helmet.listings.title',
                          defaultMessage: 'Available Rentals | Groma',
                          description: 'Listings page title',
                        })}
                      </title>
                      <meta
                        name="description"
                        content={formatMessage({
                          id: 'helmet.listings.description',
                          defaultMessage: 'Rent with us! View all units available for rent from Groma.',
                          description: 'Listings page description',
                        })}
                      />
                    </Helmet>
                  }
                />
                <Route path={AppRoutes.Partnerships} element={withTheme(<Partnerships />, customDarkTheme)} />
                <Route path={AppRoutes.Press}>
                  <Route index element={<Press />} />
                  <Route path={AppRoutes.PressRelease} element={<PressRelease />} />
                </Route>
                <Route path={AppRoutes.Privacy} element={<Privacy />} />
                <Route path={AppRoutes.Realty} element={<Realty />} />
                <Route path={AppRoutes.Terms} element={<Terms />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Suspense>
            {/* TenantTurner widget */}
            <div
              id="ttRentalList"
              className={classNames(
                styles.listings,
                'container-fluid',
                pathname !== AppRoutes.Listings && styles.hidden
              )}
              {...getTestIdAttribute('rentalList')}
            />
          </ComponentFactory.ErrorBoundary>
        </ToastProvider>
      </div>
      {showHeaderAndFooter && <ComponentFactory.Footer />}
    </>
  );
});

const App = observer(() => {
  const styles = getAppStyles();
  const { Metrics } = AppConfig.Settings;
  const { Firebase } = Metrics;
  const { pathname, hash } = useLocation();
  const resetRef = createRef<HTMLSpanElement>();

  const initializePageViewListener = () => {
    const { firebase } = useAppStore();
    const location = useLocation();
    firebase.fireEvent(FirebaseEvents.PAGE_VIEW, { path: location.pathname });
  };

  if (Firebase.enabled) {
    initializePageViewListener();
  }

  useEffect(() => {
    if (hash) jumpToAnchorRef();
    else {
      scrollToTop();
      // Move focus to top of the page
      resetRef.current?.focus();
    }
  }, [pathname]);

  return (
    <>
      <span ref={resetRef} tabIndex={-1} />
      <BypassBlock id="mainContent">
        <FormattedMessage
          id="common.bypassBlocks.mainContent"
          defaultMessage="Skip to Main Content"
          description="Main content bypass block"
        />
      </BypassBlock>
      <div className={styles.mainContainer}>
        {/* ErrorBoundary catches any Top Level errors */}
        <ComponentFactory.ErrorBoundary isFatal>
          <TooltipProvider>
            <AuthRouter>
              <ModalProvider>
                <AppContent />
              </ModalProvider>
            </AuthRouter>
          </TooltipProvider>
        </ComponentFactory.ErrorBoundary>
      </div>
    </>
  );
});

export default App;
