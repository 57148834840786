import { createStyleSheet, GlobalThemeInterface, maxContainerWidth } from '@groma-dev/blueprint';

export const getHeroStyles = createStyleSheet(
  (theme: GlobalThemeInterface) => ({
    mainContainer: {
      width: '100%',
      height: '80vh',
      maxHeight: '700px',
      minHeight: '500px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    empty: {
      height: '400px',
      minHeight: '400px',
    },
    coverImage: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    coverWrapper: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      position: 'relative',
      width: '100%',
    },
    rowWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    columnWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    reverseOrder: {
      flexDirection: 'column-reverse',
    },
    leftContentContainer: {
      width: '50vw',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      backgroundColor: theme.colors.$primary300,
      padding: `${theme.spacing.$s7}`,
    },
    rightImageContainer: {
      width: '50vw',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    rightContentContainer: {
      width: '50vw',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      backgroundColor: theme.colors.$primary300,
      padding: `${theme.spacing.$s7}`,
    },
    leftImageContainer: {
      width: '50vw',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    imageOverlay: {
      background: 'linear-gradient(177.67deg, #293831 7.99%, rgba(41, 56, 49, 0) 103.82%)',
      width: '100%',
      height: '100%',
    },
    topImageContainer: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    bottomContentContainer: {
      width: '100%',
      maxWidth: `${maxContainerWidth}px`,
      margin: '0 auto',
      marginTop: '-150px',
    },
    coverContentContainer: {
      width: '100%',
      maxWidth: `${maxContainerWidth}px`,
    },
    coverContent: {
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      paddingLeft: theme.spacing.$s7,
      width: '75%',
    },
    heroContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    heroImageRight: {
      width: '100%',
      height: '100%',
    },
    [`@media (max-width: ${theme.breakpoints.$md}px)`]: {
      empty: {
        maxHeight: '300px',
        minHeight: '300px',
      },
      rowWrapper: {
        flexDirection: 'column',
        '&$reverseOrder': {
          flexDirection: 'column-reverse',
        },
      },
      leftContentContainer: {
        width: '100%',
        padding: `${theme.spacing.$s4} ${theme.spacing.$s5}`,
      },
      rightContentContainer: {
        width: '100%',
        padding: `${theme.spacing.$s4} ${theme.spacing.$s5}`,
      },
      rightImageContainer: {
        width: '100%',
      },
      leftImageContainer: {
        width: '100%',
      },
    },
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
      mainContainer: {
        height: '100%',
        maxHeight: 'none',
        minHeight: 'auto',
        alignItems: 'flex-start',
      },
      empty: {
        height: '250px',
        minHeight: '250px',
      },
      heroContainer: {
        minHeight: '300px',
      },
      coverImage: {
        backgroundPosition: '80%',
      },
      coverContentContainer: {
        height: '80vh',
      },
      coverContent: {
        width: '100%',
        padding: `${theme.spacing.$s4} ${theme.spacing.$s5}`,
      },
      topImageContainer: {
        minHeight: '300px',
      },
      bottomContentContainer: {
        marginTop: 0,
      },
      rightImageContainer: {
        minHeight: '250px',
      },
      leftImageContainer: {
        minHeight: '250px',
      },
    },
  }),
  { name: 'Hero' }
);
