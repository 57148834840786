import React, { ErrorInfo, ReactNode } from 'react';
import { ComponentFactory } from '../../ComponentFactory';

interface ErrorBoundaryState {
  hasError: boolean;
}

export interface ErrorBoundaryProps {
  children: ReactNode;
  isFatal: boolean | undefined;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    this.logErrorToMyService(error, errorInfo);
  }

  logErrorToMyService(error: Error, errorInfo: ErrorInfo) {
    console.error(error);
    console.error('Error Info', errorInfo);
  }

  render() {
    return (
      <ComponentFactory.ErrorBoundaryContent isFatal={this.props.isFatal} hasError={this.state.hasError}>
        {this.props.children}
      </ComponentFactory.ErrorBoundaryContent>
    );
  }
}

export default ErrorBoundary;
