import React, { createRef, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AppBar, NavItem, Toolbar } from '@groma-dev/navigation';
import {
  ArrowLeftIcon,
  classNames,
  CloseIcon,
  FullLightLogo,
  getFocusableElements,
  getTestIdAttribute,
  MenuIcon,
  setFocusableOnElement,
} from '@groma-dev/blueprint';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { AppConfig } from '../../../core/AppConfig';
import { MenuNavItem } from './menunavitem/MenuNavItem';
import { MobileMenu } from './mobilemenu/MobileMenu';
import styles from './Header.module.scss';

export enum HeaderMenu {
  ABOUT = 'about',
  ACCOUNT = 'account',
  INVEST = 'invest',
  PARTNER = 'partner',
  RENT = 'rent',
  SELL = 'sell',
}

export interface HeaderProps {}

const Header = observer(() => {
  const { Domains } = AppConfig.Settings;
  const loginRef = createRef<HTMLDivElement>();
  const elementsRef = useRef<NodeList>();
  let loginTimeout: NodeJS.Timeout;
  const { pathname } = useLocation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [submenuOpen, setSubmenuOpen] = useState<boolean>(false);
  const [showLogin, setShowLogin] = useState<boolean>(false);

  const handleToggleSubmenu = (value: boolean) => {
    setSubmenuOpen(value);
  };

  const handleShowLogin = () => {
    if (loginTimeout) clearTimeout(loginTimeout);
    setTimeout(() => setShowLogin(true), 150);
  };

  const handleHideLogin = (e: React.MouseEvent & React.FocusEvent<HTMLDivElement, Element>) => {
    if (e.relatedTarget instanceof Window || (loginRef.current && !loginRef.current?.contains(e.relatedTarget))) {
      loginTimeout = setTimeout(() => {
        setShowLogin(false);
      }, 225);
    }
  };

  const handleResize = () => {
    if (window.innerWidth >= 1024) {
      setMenuOpen(false);
      setSubmenuOpen(false);
    }
  };

  useEffect(() => {
    if (menuOpen) {
      // when open, make all page elements -1
      const els = getFocusableElements();
      elementsRef.current = els;
      setFocusableOnElement(els, -1);
      // reset appBar elements to tabbable
      setFocusableOnElement(getFocusableElements('appBar'), 0);
    } else {
      // make sure all page elements are tabbable when closed
      setFocusableOnElement(elementsRef.current, 0);
    }
  }, [menuOpen]);

  useEffect(() => {
    setMenuOpen(false);
    setSubmenuOpen(false);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <AppBar
        id="appBar"
        className={classNames(styles.appBar, submenuOpen && styles.submenu)}
        position="fixed"
        {...getTestIdAttribute('header')}>
        <Toolbar className={classNames(styles.toolbarWrapper)}>
          {submenuOpen ? (
            <NavItem
              as="button"
              testId="menuBackButton"
              className={classNames(styles.backButton)}
              onClick={() => setSubmenuOpen(false)}>
              <ArrowLeftIcon />
              <FormattedMessage
                id="header.menu.backButton"
                description="Header menu back button text"
                defaultMessage="Main Menu"
              />
            </NavItem>
          ) : (
            <NavItem to="/" as={Link} aria-label="Header logo home link">
              <FullLightLogo className={classNames(styles.logo)} />
            </NavItem>
          )}
          <nav className={classNames(styles.navContainer)} {...getTestIdAttribute('nav')}>
            <MenuNavItem name={HeaderMenu.INVEST} subRoutes={['/', '/faq', '/materials']}>
              <FormattedMessage
                id="header.navItem.invest"
                description="Header nav item for invest"
                defaultMessage="Invest"
              />
            </MenuNavItem>
            <MenuNavItem name={HeaderMenu.RENT} subRoutes={['/listings']}>
              <FormattedMessage id="header.navItem.rent" description="Header nav item for rent" defaultMessage="Rent" />
            </MenuNavItem>
            <MenuNavItem name={HeaderMenu.PARTNER} subRoutes={['/partner']}>
              <FormattedMessage
                id="header.navItem.partner"
                description="Header nav item for partner"
                defaultMessage="Partner"
              />
            </MenuNavItem>
            <MenuNavItem name={HeaderMenu.SELL} subRoutes={['/partner']}>
              <FormattedMessage id="header.navItem.sell" description="Header nav item for sell" defaultMessage="Sell" />
            </MenuNavItem>
            <MenuNavItem name={HeaderMenu.ABOUT} subRoutes={['/about', '/careers', '/contact', '/press']}>
              <FormattedMessage
                id="header.navItem.about"
                description="Header nav item for about"
                defaultMessage="About"
              />
            </MenuNavItem>
          </nav>
          <div className={classNames(styles.rightContainer)}>
            <div className={classNames(styles.loginContainer)}>
              <button
                className={classNames(styles.signInButton)}
                onBlur={handleHideLogin}
                onClick={handleShowLogin}
                onMouseEnter={handleShowLogin}
                onMouseLeave={handleHideLogin}
                {...getTestIdAttribute('signIn')}>
                <FormattedMessage
                  id="header.account.signIn"
                  defaultMessage="Sign In"
                  description="Account Sign In Button"
                />
              </button>
              <div
                role="presentation"
                onBlur={handleHideLogin}
                onClick={handleShowLogin}
                onMouseEnter={handleShowLogin}
                onMouseLeave={handleHideLogin}
                className={classNames(styles.loginPopup, showLogin && styles.visible)}
                ref={loginRef}>
                <NavItem
                  href={Domains.investors.baseUrl}
                  className={classNames(styles.loginLink)}
                  testId="accountInvestorPortal">
                  <FormattedMessage
                    id="header.login.investor"
                    description="Header login link for investor portal"
                    defaultMessage="Investor Portal"
                  />
                </NavItem>
                <NavItem
                  href="https://groma.managebuilding.com/Resident/portal/login"
                  className={classNames(styles.loginLink)}
                  testId="accountTenantPortal">
                  <FormattedMessage
                    id="header.login.tenant"
                    description="Header login link for resident center"
                    defaultMessage="Resident Center"
                  />
                </NavItem>
              </div>
            </div>
            <div className={classNames(styles.menuButtonContainer)}>
              <button
                className={classNames(styles.menuButton)}
                onClick={() => setMenuOpen(!menuOpen)}
                aria-label={menuOpen ? 'Close Menu' : 'Open Menu'}
                {...getTestIdAttribute('menuButton')}>
                {menuOpen ? <CloseIcon /> : <MenuIcon />}
              </button>
            </div>
          </div>
        </Toolbar>
        <MobileMenu open={menuOpen} submenuOpen={submenuOpen} toggleSubmenu={handleToggleSubmenu} />
      </AppBar>
      <Toolbar />
    </>
  );
});

export default Header;
