import isNil from 'lodash/isNil';

export class AppError extends Error {
  code = -1;

  constructor(error?: Error) {
    super();

    if (!isNil(error)) {
      this.name = error.name;
      this.message = error.message;
      this.stack = error.stack;
    }
  }
}
