import { AppConfig } from '../AppConfig';
import { NexusRoutes } from '../routes';
import {
  ContactUsRequest,
  ContactUsResponse,
  JobDepartmentsRequest,
  JobDepartmentsResponse,
  PressRequest,
  PressResponse,
  PressReleaseRequest,
  PressReleaseResponse,
  GromaTeamRequest,
  GromaTeamResponse,
  AdvisoryBoardRequest,
  AdvisoryBoardResponse,
  RealtyTeamRequest,
  RealtyTeamResponse,
  BoardOfDirectorsRequest,
  BoardOfDirectorsResponse,
  FetchFAQItemsRequest,
  FetchFAQItemsResponse,
  FetchGromaREITStatsRequest,
  FetchGromaREITStatsResponse,
} from './APIClientInterface';
import { BaseAPIClient } from './BaseAPIClient';

export class APIClient extends BaseAPIClient {
  static async getJobDepartments(
    request: JobDepartmentsRequest,
    abortController: AbortController
  ): Promise<JobDepartmentsResponse> {
    const extraConfig = {
      baseURL: AppConfig.Pages.Careers.baseUrl,
      signal: abortController.signal,
      withCredentials: false,
    };

    const { client, config } = this.createClient({}, extraConfig);
    return client
      .get('/departments', config)
      .then(httpResponse => {
        const response = this.prepareResponse<JobDepartmentsResponse>(request, httpResponse);
        if (response.success) response.departments = httpResponse.data.departments;

        return response;
      })
      .catch(error => this.prepareResponse(request, error));
  }

  static async sendContactUsMessage(request: ContactUsRequest): Promise<ContactUsResponse> {
    const { client, config } = this.createClient();

    return client
      .post(NexusRoutes.ContactUs, request, config)
      .then(httpResponse => {
        const response = this.prepareResponse<ContactUsResponse>(request, httpResponse);
        return response;
      })
      .catch(error => this.prepareResponse<ContactUsResponse>(request, error));
  }

  static async getPress(request: PressRequest, abortController: AbortController): Promise<PressResponse> {
    const { baseUrl } = request;
    const { client, config } = this.createClient(
      {},
      { baseURL: baseUrl, signal: abortController.signal, withCredentials: false }
    );

    return client
      .get(`${baseUrl}/press-releases/index.json`, config)
      .then(httpResponse => {
        const response = this.prepareResponse<PressResponse>(request, httpResponse);
        if (response.success && httpResponse.data) response.releases = httpResponse.data.releases;
        return response;
      })
      .catch(error => this.prepareResponse<PressResponse>(request, error));
  }

  static async getPressRelease(
    request: PressReleaseRequest,
    abortController: AbortController
  ): Promise<PressReleaseResponse> {
    const { baseUrl, slug } = request;
    const { client, config } = this.createClient(
      {},
      { baseURL: baseUrl, signal: abortController.signal, withCredentials: false }
    );

    return client
      .get(`${baseUrl}/press-releases/${slug}.json`, config)
      .then(httpResponse => {
        const response = this.prepareResponse<PressReleaseResponse>(request, httpResponse);
        if (response.success && httpResponse.data) response.release = httpResponse.data;
        return response;
      })
      .catch(error => this.prepareResponse<PressReleaseResponse>(request, error));
  }

  static async getGromaTeam(request: GromaTeamRequest, abortController: AbortController): Promise<GromaTeamResponse> {
    const { baseUrl } = request;
    const { client, config } = this.createClient(
      {},
      { baseURL: baseUrl, signal: abortController.signal, withCredentials: false }
    );

    return client
      .get(`${baseUrl}/teams/gromaTeam.json`, config)
      .then(httpResponse => {
        const response = this.prepareResponse<GromaTeamResponse>(request, httpResponse);
        if (response.success && httpResponse.data) response.teamMembers = httpResponse.data.teamMembers;
        return response;
      })
      .catch(error => this.prepareResponse<GromaTeamResponse>(request, error));
  }

  static async getAdvisoryBoard(
    request: AdvisoryBoardRequest,
    abortController: AbortController
  ): Promise<AdvisoryBoardResponse> {
    const { baseUrl } = request;
    const { client, config } = this.createClient(
      {},
      { baseURL: baseUrl, signal: abortController.signal, withCredentials: false }
    );

    return client
      .get(`${baseUrl}/teams/advisoryBoard.json`, config)
      .then(httpResponse => {
        const response = this.prepareResponse<AdvisoryBoardResponse>(request, httpResponse);
        if (response.success && httpResponse.data) response.boardMembers = httpResponse.data.boardMembers;
        return response;
      })
      .catch(error => this.prepareResponse<AdvisoryBoardResponse>(request, error));
  }

  static async getRealtyTeam(
    request: RealtyTeamRequest,
    abortController: AbortController
  ): Promise<RealtyTeamResponse> {
    const { baseUrl } = request;
    const { client, config } = this.createClient(
      {},
      { baseURL: baseUrl, signal: abortController.signal, withCredentials: false }
    );

    return client
      .get(`${baseUrl}/teams/realtyTeam.json`, config)
      .then(httpResponse => {
        const response = this.prepareResponse<RealtyTeamResponse>(request, httpResponse);
        if (response.success && httpResponse.data) response.teamMembers = httpResponse.data.teamMembers;
        return response;
      })
      .catch(error => this.prepareResponse<RealtyTeamResponse>(request, error));
  }

  static async getBoardOfDirectors(
    request: BoardOfDirectorsRequest,
    abortController: AbortController
  ): Promise<BoardOfDirectorsResponse> {
    const { baseUrl } = request;
    const { client, config } = this.createClient(
      {},
      { baseURL: baseUrl, signal: abortController.signal, withCredentials: false }
    );

    return client
      .get(`${baseUrl}/teams/boardOfDirectors.json`, config)
      .then(httpResponse => {
        const response = this.prepareResponse<BoardOfDirectorsResponse>(request, httpResponse);
        if (response.success && httpResponse.data) response.boardMembers = httpResponse.data.boardMembers;
        return response;
      })
      .catch(error => this.prepareResponse<BoardOfDirectorsResponse>(request, error));
  }

  static async getFAQItems(
    request: FetchFAQItemsRequest,
    abortController: AbortController
  ): Promise<FetchFAQItemsResponse> {
    const { baseUrl } = request;
    const { client, config } = this.createClient(
      {},
      { baseURL: baseUrl, signal: abortController.signal, withCredentials: false }
    );

    return client
      .get(`${baseUrl}/faqs/index.json`, config)
      .then(httpResponse => {
        const response = this.prepareResponse<FetchFAQItemsResponse>(request, httpResponse);
        if (response.success && httpResponse.data) response.faqItems = httpResponse.data.faqItems;
        return response;
      })
      .catch(error => this.prepareResponse<FetchFAQItemsResponse>(request, error));
  }

  static async getGromaREITStats(
    request: FetchGromaREITStatsRequest,
    abortController: AbortController
  ): Promise<FetchGromaREITStatsResponse> {
    const { baseUrl } = request;
    const { client, config } = this.createClient(
      {},
      { baseURL: baseUrl, signal: abortController.signal, withCredentials: false }
    );

    return client
      .get(`${baseUrl}/reit-stats/index.json`, config)
      .then(httpResponse => {
        const response = this.prepareResponse<FetchGromaREITStatsResponse>(request, httpResponse);
        if (response.success && httpResponse.data) response.REITStats = httpResponse.data;
        return response;
      })
      .catch(error => this.prepareResponse<FetchGromaREITStatsResponse>(request, error));
  }
}
