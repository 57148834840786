import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ArrowRightIcon, classNames } from '@groma-dev/blueprint';
import { Avatar, NavItem, parseUserInitials } from '@groma-dev/navigation';
import { FormattedMessage } from 'react-intl';
import { useDataStore } from '../../../providers/StoresProvider';
import { HeaderMenu } from '../Header';
import { Menu } from '../menu/Menu';
import styles from './MobileMenu.module.scss';

export interface MobileMenuProps {
  open: boolean;
  submenuOpen: boolean;
  toggleSubmenu: (value: boolean) => void;
  signOutUser?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export const MobileMenu = observer((props: MobileMenuProps) => {
  const { open, submenuOpen, toggleSubmenu, signOutUser } = props;
  const dataStore = useDataStore();
  const [submenuSection, setSubmenuSection] = useState<HeaderMenu | undefined>();

  const handleWindowScroll = () => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.removeProperty('overflow');
    }
  };

  const handleItemClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    toggleSubmenu(true);
    setSubmenuSection(e.currentTarget.name as HeaderMenu);
  };

  useEffect(() => {
    handleWindowScroll();

    if (!open) {
      toggleSubmenu(false);
      setSubmenuSection(undefined);
    }
  }, [open]);

  return (
    <>
      <nav
        aria-label="menu"
        className={classNames(styles.menuContainer, open && styles.open, submenuOpen && styles.hidden)}>
        <ul className={classNames(styles.menuList)}>
          <li>
            <NavItem
              as="button"
              testId={`${HeaderMenu.INVEST}MobileNavItem`}
              name={HeaderMenu.INVEST}
              className={classNames(styles.menuItem)}
              onClick={handleItemClick}>
              <FormattedMessage
                id="header.mobileMenu.invest"
                description="Header mobile menu invest nav item text"
                defaultMessage="Invest"
              />
              <ArrowRightIcon />
            </NavItem>
          </li>
          <li>
            <NavItem
              as="button"
              testId={`${HeaderMenu.RENT}MobileNavItem`}
              name={HeaderMenu.RENT}
              className={classNames(styles.menuItem)}
              onClick={handleItemClick}>
              <FormattedMessage
                id="header.mobileMenu.rent"
                description="Header mobile menu rent nav item text"
                defaultMessage="Rent"
              />
              <ArrowRightIcon />
            </NavItem>
          </li>
          <li>
            <NavItem
              as="button"
              name={HeaderMenu.PARTNER}
              testId={`${HeaderMenu.PARTNER}MobileNavItem`}
              className={classNames(styles.menuItem)}
              onClick={handleItemClick}>
              <FormattedMessage
                id="header.mobileMenu.partner"
                description="Header mobile menu partner nav item text"
                defaultMessage="Partner"
              />
              <ArrowRightIcon />
            </NavItem>
          </li>
          <li>
            <NavItem
              as="button"
              name={HeaderMenu.SELL}
              testId={`${HeaderMenu.SELL}MobileNavItem`}
              className={classNames(styles.menuItem)}
              onClick={handleItemClick}>
              <FormattedMessage
                id="header.mobileMenu.sell"
                description="Header mobile menu sell nav item text"
                defaultMessage="Sell"
              />
              <ArrowRightIcon />
            </NavItem>
          </li>
          <li>
            <NavItem
              as="button"
              name={HeaderMenu.ABOUT}
              testId={`${HeaderMenu.ABOUT}MobileNavItem`}
              className={classNames(styles.menuItem)}
              onClick={handleItemClick}>
              <FormattedMessage
                id="header.mobileMenu.about"
                description="Header mobile menu about nav item text"
                defaultMessage="About"
              />
              <ArrowRightIcon />
            </NavItem>
          </li>
          <li>
            {dataStore.userState.user ? (
              <NavItem
                as="button"
                testId={`${HeaderMenu.ACCOUNT}MobileNavItem`}
                className={classNames(styles.menuItem)}
                onClick={handleItemClick}>
                <span className={classNames(styles.profileContent)}>
                  <Avatar>{parseUserInitials(dataStore.userState.user)}</Avatar>
                  <FormattedMessage
                    id="header.mobileMenu.account.profile"
                    description="Header mobile menu account profile text"
                    defaultMessage="Profile"
                  />
                </span>
                <ArrowRightIcon />
              </NavItem>
            ) : (
              <NavItem
                as="button"
                testId="signInMobile"
                className={classNames(styles.menuItem)}
                onClick={handleItemClick}>
                <FormattedMessage
                  id="header.mobileMenu.signIn"
                  description="Header mobile menu signin text"
                  defaultMessage="Sign In"
                />
                <ArrowRightIcon />
              </NavItem>
            )}
          </li>
        </ul>
      </nav>
      <nav aria-label="submenu" className={classNames(styles.submenuContainer, submenuOpen && styles.open)}>
        <Menu section={submenuSection} signOutUser={signOutUser} />
      </nav>
    </>
  );
});
