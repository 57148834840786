import isNil from 'lodash/isNil';
import { action, makeObservable, observable } from 'mobx';
import { Firebase, FirebaseConfigInterface } from '@groma-dev/blueprint';
import { AppMode, AppStoreState } from './AppStoreInterface';

export default class AppStore {
  /**
   * CLASS VARIABLES
   */
  private static instance: any = null;

  mobileSmallBreakpoint = 768;

  mobileLargeBreakpoint = 1024;

  firebase: any = undefined;

  /**
   * MOBX OBSERVABLES
   */
  @observable
  state: AppStoreState = {
    mode: this.evaluateMode(),
    firebaseInitialized: false,
  };

  constructor() {
    if (isNil(AppStore.instance)) {
      AppStore.instance = this;
    }

    window.addEventListener('resize', () => {
      this.handleWindowResize();
      this.setDocHeight();
    });
    window.addEventListener('orientationchange', this.setDocHeight);

    this.setDocHeight();

    makeObservable(this);

    // AppStore is a singleton class
    // eslint-disable-next-line no-constructor-return
    return AppStore.instance;
  }

  initializeFirebase = (config: FirebaseConfigInterface) => {
    if (!this.state.firebaseInitialized) {
      this.firebase = new Firebase(config);
      this.state.firebaseInitialized = true;
    }
  };

  private evaluateMode(): AppMode {
    const { mobileSmallBreakpoint, mobileLargeBreakpoint } = this;

    return window.innerWidth < mobileSmallBreakpoint
      ? AppMode.MobileSmall
      : window.innerWidth < mobileLargeBreakpoint && window.innerWidth >= mobileSmallBreakpoint
      ? AppMode.MobileLarge
      : AppMode.Desktop;
  }

  private setDocHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }

  private handleWindowResize = () => {
    const mode = this.evaluateMode();
    this.setMode(mode);
  };

  /**
   * MOBX ACTIONS
   */
  @action
  setMode(mode: AppMode) {
    this.state.mode = mode;
  }
}
