import { createStyleSheet, GlobalThemeInterface, headerHeight, vh } from '@groma-dev/blueprint';

const pseudoMaxWidth = '1000px';

export const getPressReleaseStyles = createStyleSheet(
  (theme: GlobalThemeInterface) => ({
    mainWrapper: {
      backgroundColor: theme.colors.$background100,
      height: '100%',
      minHeight: `calc(${vh()} - ${headerHeight}px)`,
      width: '100%',
      whiteSpace: 'pre-line',
    },
    article: {
      margin: '0 auto',
      maxWidth: pseudoMaxWidth,
      padding: `0 ${theme.spacing.$s5}`,
      width: '100%',
    },
    header: {
      backgroundColor: theme.colors.$primary300,
    },
    headerContent: {
      padding: `${theme.spacing.$s6} ${theme.spacing.$s5} 0`,
      margin: '0 auto',
      maxWidth: pseudoMaxWidth,
    },
    articleTitle: {
      ...theme.typography.$textPrimary550,
      color: theme.colors.$neutral100,
      // DIFF lineHeight: '42px',
      margin: '0 auto',
      padding: `${theme.spacing.$s4} 0`,
    },
    articleSubtitle: {
      ...theme.typography.$textPrimary300,
      color: theme.colors.$neutral100,
      margin: '0 auto',
      padding: `0 0 ${theme.spacing.$s6}`,
    },
    dateBlock: {
      display: 'flex-column',
      margin: '0 auto',
      '& $link': {
        paddingBottom: theme.spacing.$s7,
      },
    },
    articleDate: {
      ...theme.typography.$textPrimary250,
      color: theme.colors.$primary100,
      // DIFF lineHeight: '22px',
    },
    content: {
      ...theme.typography.$textPrimary200,
      color: theme.colors.$content200,
    },
    textContainer: {
      padding: `${theme.spacing.$s6} 0`,
      '& a': {
        color: theme.colors.$interactiveLinkDefault,
        '&:hover,&:focus': {
          color: theme.colors.$interactiveLinkHover,
        },
      },
      '& ul': {
        paddingLeft: `${theme.spacing.$s4}`,
        textIndent: '-20px',
      },
      '& li': {
        listStyle: 'inside',
      },
    },
    imageContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.spacing.$s6,
      justifyContent: 'center',
      margin: '0 auto',
      maxWidth: '1200px',
      padding: `0 ${theme.spacing.$s5}`,
      width: '100%',
    },
    image: {
      maxWidth: '100%',
      width: 'inherit',
    },
    metaWrapper: {
      margin: '0 auto',
      maxWidth: pseudoMaxWidth,
      padding: `0 ${theme.spacing.$s5}`,
    },
    metaContainer: {
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: '#678D7C',
      padding: `0${theme.spacing.$s5} 0`,
    },
    metaTitle: {
      ...theme.typography.$textPrimary350,
      color: theme.colors.$content200,
    },
    metaContent: {
      padding: `${theme.spacing.$s4} 0`,
      '& a': {
        color: theme.colors.$interactiveLinkDefault,
        '&:hover,&:focus': {
          color: theme.colors.$interactiveLinkHover,
        },
      },
    },
    link: {
      flexDirection: 'row',
      position: 'relative',
      '& a': {
        flexDirection: 'row',
        overflow: 'visible',
        padding: 0,
      },
    },
    linkIcon: {
      height: '20px',
      width: '20px',
      '&$left': {
        marginRight: theme.spacing.$s1,
      },
      '&$right': {
        marginLeft: theme.spacing.$s1,
      },
      '& path': {
        fill: theme.colors.$secondary300,
      },
    },
    paddingBottom: {
      paddingBottom: theme.spacing.$s4,
    },
    soloImage: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    left: {},
    right: {},
    [`@media (max-width: ${theme.breakpoints.$sm}px)`]: {
      article: {
        padding: `0 ${theme.spacing.$s4}`,
      },
      headerContent: {
        padding: `${theme.spacing.$s6} ${theme.spacing.$s4} 0`,
      },
      articleTitle: {
        ...theme.typography.$textPrimary450,
        color: theme.colors.$neutral100,
        // DIFF lineHeight: '32px',
      },
      articleSubtitle: {
        ...theme.typography.$textPrimary200,
        color: theme.colors.$neutral100,
        padding: `0 0 ${theme.spacing.$s6}`,
      },
      content: {
        ...theme.typography.$textPrimary100,
        color: theme.colors.$content200,
      },
      textContainer: {
        padding: `${theme.spacing.$s4} 0`,
      },
      imageContainer: {
        gap: theme.spacing.$s3,
        gridTemplateColumns: '1fr',
        padding: 0,
        width: 'fit-content',
      },
      metaWrapper: {
        padding: `0 ${theme.spacing.$s4}`,
      },
      metaContainer: {
        padding: `${theme.spacing.$s4} 0 0`,
      },
      metaTitle: {
        ...theme.typography.$textPrimary250,
        color: theme.colors.$content200,
      },
      linkIcon: {
        '&$left': {
          marginRight: theme.spacing.$s2,
        },
        '&$right': {
          marginLeft: theme.spacing.$s2,
        },
      },
    },
  }),
  { name: 'PressRelease' }
);
