import React from 'react';
import { useTheme, Spinner, getTestIdAttribute, GlobalBaseTheme } from '@groma-dev/blueprint';
import { observer } from 'mobx-react-lite';
import { getLoadingStyles } from './styles/Loading.styles';

export interface LoadingProps {
  color?: string;
  height?: string;
  isPage?: boolean;
  testId?: string;
}

const Loading = observer((props: LoadingProps) => {
  const theme = useTheme();

  const {
    color = theme.__base === GlobalBaseTheme.DARK ? theme.colors.$secondary300 : theme.colors.$primary300,
    height = '50px',
    isPage = false,
    testId = 'loading',
  } = props;
  const styles = getLoadingStyles({ isPage });
  const dti = getTestIdAttribute(testId);

  return (
    <div className={styles.mainContainer} {...dti}>
      <div style={{ width: height, height }}>
        <Spinner color={color} />
      </div>
    </div>
  );
});

export default Loading;
