import React, { ReactNode } from 'react';
import { Helmet } from '@groma-dev/blueprint';
import { AppConfig } from '../../../core/AppConfig';

export interface ScriptInjectorProps {
  children: ReactNode;
}

const ScriptInjector = (props: ScriptInjectorProps) => {
  const { children } = props;
  const { enabled: ScriptInjectorEnabled } = AppConfig.Components.ScriptInjector;

  return ScriptInjectorEnabled ? <Helmet>{children}</Helmet> : null;
};

export default ScriptInjector;
