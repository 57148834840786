/* eslint-disable max-len */
import React, { createRef, Suspense, useEffect, useState } from 'react';
import { classNames, Link } from '@groma-dev/blueprint';
import { observer } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ComponentFactory } from '../../ComponentFactory';
import { HeroImagePosition } from '../../components/hero/Hero';
import { AppRoutes } from '../../../core/routes';
import { buildUrlWithParams, isProduction } from '../../utils/WebHelper';
import { useDataStore } from '../../providers/StoresProvider';
import { GromaTeamMember, BoardMember } from '../../../core/models';
import { getAboutStyles } from './styles/About.styles';

const heroImageWebP = require('./assets/heroImage.webp');
const heroImage = require('./assets/heroImage.png');

const AdvisoryBoard = React.lazy(() => ComponentFactory.AdvisoryBoardAsync);
const BoardOfDirectors = React.lazy(() => ComponentFactory.BoardOfDirectorsAsync);
const GromaTeam = React.lazy(() => ComponentFactory.GromaTeamAsync);
const Hero = React.lazy(() => ComponentFactory.HeroAsync);
const Section = React.lazy(() => ComponentFactory.SectionAsync);

export enum AboutView {
  Team = 'team',
  AdvisoryBoard = 'advisory-board',
  BoardOfDirectors = 'board-of-directors',
}

export interface AboutProps {
  view: AboutView;
}

const About = observer((props: AboutProps) => {
  const { view } = props;
  const styles = getAboutStyles();
  const {
    gromaTeamState,
    getGromaTeam,
    getAdvisoryBoard,
    advisoryBoardState,
    boardOfDirectorsState,
    getBoardOfDirectors,
  } = useDataStore();

  const { pathname } = useLocation();
  const [gromaTeam, setGromaTeam] = useState<GromaTeamMember[]>([]);
  const [advisoryBoard, setAdvisoryBoard] = useState<BoardMember[]>([]);
  const [boardOfDirectors, setBoardOfDirectors] = useState<BoardMember[]>([]);

  const navRef = createRef<HTMLUListElement>();
  const navItemsRef = {
    gromaTeam: createRef<HTMLLIElement>(),
    advisoryBoard: createRef<HTMLLIElement>(),
    boardOfDirectors: createRef<HTMLLIElement>(),
  };

  const handleFetchGromaTeam = async (abortController: any) => {
    const response = await getGromaTeam(abortController);
    if (response.success) {
      setGromaTeam(response.teamMembers);
    }
  };

  const handleFetchAdvisoryBoard = async (abortController: any) => {
    const response = await getAdvisoryBoard(abortController);
    if (response.success) {
      setAdvisoryBoard(response.boardMembers);
    }
  };

  const handleFetchBoardOfDirectors = async (abortController: any) => {
    const response = await getBoardOfDirectors(abortController);
    if (response.success) {
      setBoardOfDirectors(response.boardMembers);
    }
  };

  const handleScrollNav = () => {
    if (navRef.current) {
      const navBar = navRef.current;
      const windowWidth = window.innerWidth;
      const ref =
        pathname === AppRoutes.About
          ? navItemsRef.gromaTeam
          : pathname === AppRoutes.AdvisoryBoard
          ? navItemsRef.advisoryBoard
          : navItemsRef.boardOfDirectors;

      if (ref.current) {
        navBar.scrollLeft =
          windowWidth <= ref.current.offsetLeft + ref.current.offsetWidth
            ? ref.current.offsetLeft + ref.current.offsetWidth
            : 0;
      }
    }
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (view === AboutView.Team && !gromaTeamState.loadingTeam) {
      handleFetchGromaTeam(abortController);
    }

    if (view === AboutView.AdvisoryBoard && !advisoryBoardState.loadingMembers) {
      handleFetchAdvisoryBoard(abortController);
    }

    if (view === AboutView.BoardOfDirectors && !boardOfDirectorsState.loadingMembers) {
      handleFetchBoardOfDirectors(abortController);
    }

    return () => {
      if (isProduction()) {
        abortController.abort();
      }
    };
  }, [view]);

  useEffect(() => {
    // set scroll on updates
    handleScrollNav();
  });

  return (
    <main className={styles.mainContainer}>
      <Suspense fallback={<ComponentFactory.Loading />}>
        <Hero backgroundSrc={heroImageWebP} backgroundSrcFallback={heroImage} imagePosition={HeroImagePosition.RIGHT}>
          <h1 className={styles.title}>
            <FormattedMessage
              id="about.hero.title"
              defaultMessage="About Groma"
              description="Hero title of about page"
            />
          </h1>
          <p className={styles.subTitle}>
            <FormattedMessage
              id="about.hero.subTitle"
              defaultMessage="Groma is a vertically integrated real estate investment, development and management company combined with a tech startup building tools to provide better outcomes for our investors, tenants and the communities we serve.{br}{br}Groma was founded in early 2020 and is headquartered in Boston, MA."
              description="Hero subtitle of about page"
              values={{
                br: <br />,
              }}
            />
          </p>
        </Hero>
        <nav className={styles.navContainer}>
          <ul ref={navRef} className={styles.navWrapper}>
            <li ref={navItemsRef.gromaTeam}>
              <Link
                href={AppRoutes.About}
                className={`${styles.navLink} ${view === AboutView.Team ? styles.active : ''}`}
                testId="gromaTeamLink">
                <FormattedMessage
                  id="about.nav.team"
                  defaultMessage="Groma Team"
                  description="About page nav item for Groma Team"
                />
              </Link>
            </li>
            <li ref={navItemsRef.advisoryBoard}>
              <Link
                href={AppRoutes.AdvisoryBoard}
                className={`${styles.navLink} ${view === AboutView.AdvisoryBoard ? styles.active : ''}`}
                testId="advisoryBoardLink">
                <FormattedMessage
                  id="about.nav.advisoryBoard"
                  description="About page nav item for Advisory Board"
                  defaultMessage="Advisory Board"
                />
              </Link>
            </li>
            <li ref={navItemsRef.boardOfDirectors}>
              <Link
                href={AppRoutes.BoardOfDirectors}
                className={`${styles.navLink} ${view === AboutView.BoardOfDirectors ? styles.active : ''}`}
                testId="boardOfDirectorsLink">
                <FormattedMessage
                  id="about.nav.boardOfDirectors"
                  description="About page nav item for Board of Directors"
                  defaultMessage="GromaCorp Board of Directors"
                />
              </Link>
            </li>
          </ul>
        </nav>
        {gromaTeamState.loadingTeam || advisoryBoardState.loadingMembers || boardOfDirectorsState.loadingMembers ? (
          <ComponentFactory.Loading />
        ) : view === AboutView.Team ? (
          <>
            <Section className={classNames(styles.section)}>
              <GromaTeam teamMembers={gromaTeam} />
            </Section>
            <Section className={classNames(styles.section)}>
              <div className={styles.careerContainer}>
                <div className={styles.careerWrapper}>
                  <h2 className={styles.careerTitle}>
                    <FormattedMessage
                      id="about.sections.careers.title"
                      defaultMessage="We’re Hiring!"
                      description="Careers section title on about page"
                    />
                  </h2>
                  <p className={styles.careerContent}>
                    <FormattedMessage
                      id="about.sections.careers.content"
                      defaultMessage="Interested in joining the Groma team? We are hiring on all teams from engineering to leasing.{br}{br}Check out our career’s page to see all available roles!"
                      description="Careers content on about page"
                      values={{
                        br: <br />,
                      }}
                    />
                  </p>
                  <Link href={buildUrlWithParams(AppRoutes.Careers)} className={styles.careerLink} testId="careersLink">
                    <FormattedMessage
                      id="about.sections.careers.link"
                      defaultMessage="View open roles"
                      description="Link to careers page on Greenhouse"
                    />
                  </Link>
                </div>
              </div>
            </Section>
          </>
        ) : view === AboutView.AdvisoryBoard ? (
          <Section className={classNames(styles.section)}>
            <AdvisoryBoard boardMembers={advisoryBoard} />
          </Section>
        ) : (
          <Section className={classNames(styles.section)}>
            <BoardOfDirectors boardMembers={boardOfDirectors} />
          </Section>
        )}
      </Suspense>
    </main>
  );
});

export default About;
