import React, { ReactNode, useEffect } from 'react';
import {
  Button,
  classNames,
  createStyleSheet,
  flexMixin,
  GlobalThemeInterface,
  ToastMode,
  useToasts,
  vh,
} from '@groma-dev/blueprint';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const useStyles = createStyleSheet(
  (theme: GlobalThemeInterface) => ({
    mainContainer: {
      backgroundColor: theme.colors.$background100,
      height: vh(),
      width: '100vw',
    },
    mainWrapper: {
      ...flexMixin('column', 'center', 'center'),
      width: 'fit-content',
      maxWidth: '50vw',
      margin: `${vh(30)} auto 0`,
    },
    content: {
      ...theme.typography.$textPrimary225,
      marginBottom: theme.spacing.$s6,
    },
  }),
  { name: 'ErrorBoundaryContent' }
);
export interface ErrorBoundaryContentProps {
  children: ReactNode;
  isFatal?: boolean;
  hasError?: boolean;
}

const ErrorBoundaryContent = (props: ErrorBoundaryContentProps) => {
  const { children, isFatal = false, hasError = false } = props;
  const styles = useStyles();
  const { addToast } = useToasts();
  const { formatMessage } = useIntl();
  const messages = defineMessages({
    errorMessage: {
      id: 'errorBoundaryContent.message',
      defaultMessage: 'Something went wrong trying to load this page. Reload the page to try again.',
      description: 'ErrorBoundaryContent message text',
    },
  });

  const handleReload = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (hasError && !isFatal) {
      addToast(formatMessage({ ...messages.errorMessage }), ToastMode.ERROR);
    }
  }, [hasError]);

  return !hasError ? (
    children
  ) : isFatal ? (
    <div className={classNames(styles.mainContainer)}>
      <div className={classNames(styles.mainWrapper)}>
        <p className={classNames(styles.content)}>
          <FormattedMessage {...messages.errorMessage} />
        </p>
        <Button onClick={handleReload}>
          <FormattedMessage
            id="errorBoundaryContent.button"
            defaultMessage="Reload"
            description="ErrorBoundaryContent button text"
          />
        </Button>
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default ErrorBoundaryContent;
