import React from 'react';

/**
 *
 * STANDARD COMPONENTS
 *
 * */
import App, { AppProps } from './components/app/App';
import ErrorBoundary, { ErrorBoundaryProps } from './components/errorboundary/ErrorBoundary';
import ErrorBoundaryContent, { ErrorBoundaryContentProps } from './components/errorboundary/ErrorBoundaryContent';
import Footer, { FooterProps } from './components/footer/Footer';
import Header, { HeaderProps } from './components/header/Header';
import Loading, { LoadingProps } from './components/loading/Loading';
import ScriptInjector, { ScriptInjectorProps } from './components/scriptinjector/ScriptInjector';

type ComponentClassAsync<T> = Promise<{ default: React.ComponentClass<T> }>;

export class ComponentFactory {
  static get<T>(component: any): new () => React.Component<T> {
    return component;
  }

  static getAsync<T>(component: any): ComponentClassAsync<T> {
    return component;
  }

  static get AboutAsync() {
    return import(/* webpackChunkName: "About" */ './pages/about/About');
  }

  static get AdvisoryBoardAsync() {
    return import(/* webpackChunkName: "AdvisoryBoard" */ './components/advisoryboard/AdvisoryBoard');
  }

  static get AngledSeparatorAsync() {
    return import(/* webpackChunkName: "AngledSeparator" */ './components/angledseparator/AngledSeparator');
  }

  static get App() {
    return ComponentFactory.get<AppProps>(App);
  }

  static get AuthAsync() {
    return import(/* webpackChunkName: "Auth" */ './pages/auth/Auth');
  }

  static get BioCardAsync() {
    return import(/* webpackChunkName: "BioCard" */ './components/biocard/BioCard');
  }

  static get BioDetailAsync() {
    return import(/* webpackChunkName: "BioDetail" */ './components/biodetail/BioDetail');
  }

  static get BioTileAsync() {
    return import(/* webpackChunkName: "BioTile" */ './components/biotile/BioTile');
  }

  static get BoardOfDirectorsAsync() {
    return import(/* webpackChunkName: "BoardOfDirectors" */ './components/boardofdirectors/BoardOfDirectors');
  }

  static get CardAsync() {
    return import(/* webpackChunkName: "Card" */ './components/card/Card');
  }

  static get CardPopoverAsync() {
    return import(/* webpackChunkName: "CardPopover" */ './components/cardpopover/CardPopover');
  }

  static get CareersAsync() {
    return import(/* webpackChunkName: "Careers" */ './pages/careers/Careers');
  }

  static get CareerItemAsync() {
    return import(/* webpackChunkName: "CareerItem" */ './components/careeritem/CareerItem');
  }

  static get CareerSectionAsync() {
    return import(/* webpackChunkName: "CareerSection" */ './components/careersection/CareerSection');
  }

  static get CompanyDetailsSectionAsync() {
    return import(
      /* webpackChunkName: "CompanyDetailsSection" */ './components/companydetailssection/CompanyDetailsSection'
    );
  }

  static get ContactAsync() {
    return import(/* webpackChunkName: "CareerSection" */ './pages/contact/Contact');
  }

  static get ContactFormAsync() {
    return import(/* webpackChunkName: "ContactForm" */ './components/contactform/ContactForm');
  }

  static get ErrorBoundary() {
    return ComponentFactory.get<ErrorBoundaryProps>(ErrorBoundary);
  }

  static get ErrorBoundaryContent() {
    return ComponentFactory.get<ErrorBoundaryContentProps>(ErrorBoundaryContent);
  }

  static get ErrorPageAsync() {
    return import(/* webpackChunkName: "ErrorPage" */ './pages/errorpage/ErrorPage');
  }

  static get Footer() {
    return ComponentFactory.get<FooterProps>(Footer);
  }

  static get GromaTeamAsync() {
    return import(/* webpackChunkName: "GromaTeam" */ './components/gromateam/GromaTeam');
  }

  static get HbsptContactFormAsync() {
    return import(/* webpackChunkName: "HbsptContactForm" */ './components/hbsptcontactform/HbsptContactForm');
  }

  static get Header() {
    return ComponentFactory.get<HeaderProps>(Header);
  }

  static get HeroAsync() {
    return import(/* webpackChunkName: "Hero" */ './components/hero/Hero');
  }

  static get HeroSectionAsync() {
    return import(/* webpackChunkName: "HeroSection" */ './components/herosection/HeroSection');
  }

  static get HomeAsync() {
    return import(/* webpackChunkName: "Home" */ './pages/home/Home');
  }

  static get FAQAsync() {
    return import(/* webpackChunkName: "FAQ" */ './pages/faq/FAQ');
  }

  static get FAQSectionAsync() {
    return import(/* webpackChunkName: "FAQSection" */ './components/faqsection/FAQSection');
  }

  static get FootnoteAsync() {
    return import(/* webpackChunkName: "Footnote" */ './components/footnote/Footnote');
  }

  static get FundOneShowcaseAsync() {
    return import(/* webpackChunkName: "FundOneShowcase" */ './components/fundoneshowcase/FundOneShowcase');
  }

  static get InvestBenefitsSectionAsync() {
    return import(
      /* webpackChunkName: "InvestBenefitsSection" */ './components/investbenefitssection/InvestBenefitsSection'
    );
  }

  static get InvestSectionAsync() {
    return import(/* webpackChunkName: "InvestSection" */ './components/investsection/InvestSection');
  }

  static get InViewObserverAsync() {
    return import(/* webpackChunkName: "InViewObserver" */ './components/inviewobserver/InViewObserver');
  }

  static get Loading() {
    return ComponentFactory.get<LoadingProps>(Loading);
  }

  static get MacroMURChartsAsync() {
    return import(/* webpackChunkName: "MacroMURCharts" */ './components/macromurcharts/MacroMURCharts');
  }

  static get MemberTileAsync() {
    return import(/* webpackChunkName: "MemberTile" */ './components/membertile/MemberTite');
  }

  static get MicroMURTable() {
    return import(/* webpackChunkName: "MicroMURTable" */ './components/micomurtable/MicroMURTable');
  }

  static get MURSectionAsync() {
    return import(/* webpackChunkName: "MURSection" */ './components/mursection/MURSection');
  }

  static get PartnershipsAsync() {
    return import(/* webpackChunkName: "Partnerships" */ './pages/partnerships/Partnerships');
  }

  static get PartnershipsMapAsync() {
    return import(/* webpackChunkName: "PartnershipsMap" */ './components/partnershipsmap/PartnershipsMap');
  }

  static get PressAsync() {
    return import(/* webpackChunkName: "Press" */ './pages/press/Press');
  }

  static get PressReleaseAsync() {
    return import(/* webpackChunkName: "PressRelease" */ './pages/pressrelease/PressRelease');
  }

  static get PrivacyAsync() {
    return import(/* webpackChunkName: "Privacy" */ './pages/privacy/Privacy');
  }

  static get RealtyAsync() {
    return import(/* webpackChunkName: "Realty" */ './pages/realty/Realty');
  }

  static get ScriptInjector() {
    return ComponentFactory.get<ScriptInjectorProps>(ScriptInjector);
  }

  static get SectionAsync() {
    return import(/* webpackChunkName: "Section" */ './components/section/Section');
  }

  static get SideNavAsync() {
    return import(/* webpackChunkName: "SideNav" */ './components/sidenav/SideNav');
  }

  static get StatsAsync() {
    return import(/* webpackChunkName: "Stats" */ './components/stats/Stats');
  }

  static get TechnologySection() {
    return import(/* webpackChunkName: "TechnologySection" */ './components/technologysection/TechnologySection');
  }

  static get TermsAsync() {
    return import(/* webpackChunkName: "Terms" */ './pages/terms/Terms');
  }

  static get TrackRecordSectionAsync() {
    return import(/* webpackChunkName: "TrackRecordSection" */ './components/trackrecordsection/TrackRecordSection');
  }

  static get TrashAndRecyclingAsync() {
    return import(/* webpackChunkName: "TrashAndRecycling" */ './pages/trashandrecycling/TrashAndRecycling');
  }
}
