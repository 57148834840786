import React from 'react';
import { BaseTheme, BlueprintProvider, NewRelic, SCSSThemeProvider } from '@groma-dev/blueprint';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { getBrowserLocale } from '@groma-dev/scaffold';
import { AppConfig } from '../core/AppConfig';
import DataStore from '../core/stores/DataStore';
import { defaultLocaleMessages } from '../core/locales';
import AppStore from './stores/AppStore';
import StoresProvider from './providers/StoresProvider';
import { ComponentFactory } from './ComponentFactory';

const initializeStores = () => {
  const dataStore = new DataStore();
  const appStore = new AppStore();

  return { dataStore, appStore };
};

const AppLoader = () => {
  const { dataStore, appStore } = initializeStores();
  const { initializeFirebase } = appStore;
  const { Localization, Metrics } = AppConfig.Settings;
  const { defaultLocale, locales } = Localization;
  const language = getBrowserLocale(navigator.language);
  const currentLocale: { [key: string]: string } = locales[language];

  if (Metrics.Firebase.enabled) {
    initializeFirebase(Metrics.Firebase.config);
  }

  if (Metrics.NewRelic.enabled) {
    const newRelic = new NewRelic();
    newRelic.initializeNewRelic(Metrics.NewRelic.config);
  }

  return (
    <BrowserRouter>
      <StoresProvider appStore={appStore} dataStore={dataStore}>
        <IntlProvider locale={language} messages={currentLocale} defaultLocale={defaultLocale}>
          <BlueprintProvider cssReset defineMessages={defaultLocaleMessages}>
            <SCSSThemeProvider theme={BaseTheme}>
              <>
                <ComponentFactory.ScriptInjector>
                  {/* Start of HubSpot Embed Code */}
                  <script
                    type="text/javascript"
                    id="hs-script-loader"
                    async
                    defer
                    src="//js.hs-scripts.com/22119113.js"
                  />
                  {/* End of HubSpot Embed Code */}
                </ComponentFactory.ScriptInjector>
                <ComponentFactory.App />
              </>
            </SCSSThemeProvider>
          </BlueprintProvider>
        </IntlProvider>
      </StoresProvider>
    </BrowserRouter>
  );
};

export default AppLoader;
