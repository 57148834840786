import React, { ReactNode, useEffect, useState } from 'react';
import {
  Helmet,
  Image,
  Link,
  ArrowLeftIcon,
  ArrowRightIcon,
  getTestIdAttribute,
  classNames,
} from '@groma-dev/blueprint';
import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { AppConfig } from '../../../core/AppConfig';
import { AppRoutes } from '../../../core/routes';
import { useDataStore } from '../../providers/StoresProvider';
import { ComponentFactory } from '../../ComponentFactory';
import { PressRelease as PR, TextBlock, ImageBlock } from '../../../core/models';
import { isProduction } from '../../utils/WebHelper';
import { getPressReleaseStyles } from './styles/PressRelease.styles';

const ErrorPage = React.lazy(() => ComponentFactory.ErrorPageAsync);

export enum PressReleaseBlock {
  TEXT = 'text',
  IMAGE = 'image',
}

export interface PressReleaseProps {}

const PressRelease = observer(() => {
  const { slug } = useParams();
  const { formatMessage } = useIntl();
  const dataStore = useDataStore();
  const { pressReleasesState } = dataStore;
  const styles = getPressReleaseStyles();
  const pressReleaseTitleDti = getTestIdAttribute('pressReleaseTitle');

  const [article, setArticle] = useState<PR>();
  const [initialized, setInitialized] = useState<boolean>(false);

  const seoContent = () => {
    const { Seo } = AppConfig.Settings;
    const titleContent = formatMessage(
      { id: 'helmet.pressRelease.title', defaultMessage: '{title} | Groma', description: 'Press Release page title' },
      { title: article?.title }
    );

    return Seo.enabled ? (
      <Helmet>
        <title>{titleContent}</title>
        <meta
          name="description"
          content={formatMessage({
            id: 'helmet.pressRelease.description',
            defaultMessage: "Read Groma's announcements and press releases.",
            description: 'Press Release page description',
          })}
        />
      </Helmet>
    ) : null;
  };

  const renderArticleContent = () => {
    const { baseUrl } = AppConfig.Pages.Press.options;

    if (!article) return null;

    return (
      <div className={styles.article}>
        {article.blocks.map((section: TextBlock | ImageBlock, index) => {
          switch (section.type) {
            case PressReleaseBlock.TEXT:
              return (
                <section
                  key={index}
                  className={`${styles.textContainer} ${styles.content}`}
                  dangerouslySetInnerHTML={{ __html: section.content }}
                />
              );
            case PressReleaseBlock.IMAGE:
              return (
                <section
                  key={index}
                  className={classNames(styles.imageContainer, section.content.length === 1 && styles.soloImage)}>
                  {section.content.map((img: any) => {
                    const imgUrl = img.url.includes('~/') ? `${baseUrl}${img.url.split('~')[1]}` : img.url;
                    return <Image key={img.name} className={styles.image} src={imgUrl} alt={img.name} />;
                  })}
                </section>
              );
            default:
              return <></>;
          }
        })}
        <div className={styles.textContainer}>
          <p className={`${styles.content} ${styles.paddingBottom}`}>
            <FormattedMessage
              id="pressRelease.contactEmail"
              defaultMessage="For questions or inquiries, please email <a>press@groma.com</a>"
              description="Press release contact email"
              values={{
                a: (chunks: ReactNode) => <a href={`mailto:${chunks}`}>{chunks}</a>,
              }}
            />
          </p>
          <Link
            href={AppRoutes.Contact}
            className={styles.link}
            accessibilityLabel="contact groma"
            testId="contactGromaLink">
            <FormattedMessage
              id="pressRelease.contactLink"
              defaultMessage="Contact Groma"
              description="Press Release contact link"
            />
            <ArrowRightIcon className={`${styles.linkIcon} ${styles.right}`} />
          </Link>
        </div>
      </div>
    );
  };

  const renderMeta = () => {
    if (!article) return null;

    return (
      <section className={styles.metaWrapper}>
        {article.meta?.map((block: any) => {
          return (
            <div key={block.title} className={styles.metaContainer}>
              <h3 className={styles.metaTitle}>{block.title}</h3>
              <p
                className={`${styles.metaContent} ${styles.content}`}
                dangerouslySetInnerHTML={{ __html: block.content }}
              />
            </div>
          );
        })}
      </section>
    );
  };

  const handleGetPressRelease = async (slug: string, abortController: AbortController) => {
    const response = await dataStore.getPressRelease(slug, abortController);
    if (response.success) setArticle(response.release);
    setInitialized(true);
  };

  useEffect(() => {
    const abortController = new AbortController();

    if (!pressReleasesState.loadingPressRelease && slug) handleGetPressRelease(slug, abortController);

    return () => {
      if (isProduction()) {
        abortController.abort();
      }
    };
  }, []);

  if (initialized && !article && !pressReleasesState.loadingPressRelease) {
    return <ErrorPage />;
  }

  return pressReleasesState.loadingPressRelease ? (
    <ComponentFactory.Loading isPage />
  ) : (
    <>
      {seoContent()}
      <article className={styles.mainWrapper}>
        <header className={styles.header}>
          {article && (
            <div className={styles.headerContent}>
              <div className={styles.dateBlock}>
                {AppConfig.Pages.Press.enabled && (
                  <Link
                    href={AppRoutes.Press}
                    className={styles.link}
                    accessibilityLabel="press and announcements"
                    testId="pressLink">
                    <ArrowLeftIcon className={`${styles.linkIcon} ${styles.left}`} />
                    <FormattedMessage
                      id="pressRelease.backToPressLink"
                      defaultMessage="Press & Announcements"
                      description="Press release back link text"
                    />
                  </Link>
                )}
                <div className={styles.articleDate}>
                  <time>{article.date}</time>
                </div>
              </div>
              <h1 className={styles.articleTitle} {...pressReleaseTitleDti}>
                {article.title}
              </h1>
              <div className={styles.descriptionBlock}>
                <h2 className={styles.articleSubtitle}>{article.subtitle}</h2>
              </div>
            </div>
          )}
        </header>
        {renderArticleContent()}
        {renderMeta()}
      </article>
    </>
  );
});

export default PressRelease;
