import isNil from 'lodash/isNil';
import axios, { AxiosRequestConfig, AxiosInstance, CancelTokenSource, AxiosResponse } from 'axios';
import { AppError } from '../utils/AppError';
import { AppConfig } from '../AppConfig';

export type APIResponseCallback = (response: APIResponse) => void;

export interface APIRequestOptions {
  silent?: boolean;
  time_zone?: string;
}

export interface APIRequest {
  identifier?: string;

  callback?: APIResponseCallback;
  resolve?: Function;
  reject?: Function;

  options?: APIRequestOptions;
}

export interface APIResponse {
  request: APIRequest;

  date: Date;

  success: boolean;
  canceled?: boolean;

  status: number;
  statusText?: string;

  error?: AppError;
}

export interface BaseAPIClientOptions {
  version?: string;
}

export interface InterceptorsInterface {
  request: {
    onFulfilled?: (requestConfig: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
    onRejected?: (error: any) => any;
  };
  response: {
    onFulfilled?: (requestConfig: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
    onRejected?: (error: any) => any;
  };
}

export class BaseAPIClient {
  static interceptors: InterceptorsInterface = {
    request: {},
    response: {},
  };

  static prepareVersionHeaders = (version: string) => {
    return {
      'Content-Type': `vnd.groma.${version}+json`,
      Accept: `vnd.groma.${version}+json`,
    };
  };

  static prepareRequestHeaders(headers: any, withCredentials: boolean): any {
    const { apiVersion } = AppConfig.Settings.Server;

    if (!withCredentials) return headers;

    return {
      ...BaseAPIClient.prepareVersionHeaders(apiVersion),
      ...headers,
    };
  }

  static prepareRequestParams(params: any): any {
    return params;
  }

  static createClient = (
    options?: BaseAPIClientOptions,
    extraConfig?: AxiosRequestConfig
  ): { client: AxiosInstance; config: AxiosRequestConfig; cancelToken: CancelTokenSource } => {
    const result: any = {};
    const endpoint = AppConfig.Settings.Server.endpoints.api();

    const clientConfig: AxiosRequestConfig = {
      timeout: AppConfig.Settings.Server.timeout,
      baseURL: endpoint,
      ...extraConfig,
    };

    result.client = axios.create(clientConfig);

    // Bubble up all 4XX status codes for UI to catch / handle.
    result.client.interceptors.response.use(undefined, (error: any) => {
      if (error.response && error.response.status < 500 && error.response.status >= 400) {
        return Promise.resolve(error.response);
      } else return Promise.reject(error);
    });

    if (
      !isNil(BaseAPIClient.interceptors.request.onFulfilled) ||
      !isNil(BaseAPIClient.interceptors.request.onRejected)
    ) {
      result.client.interceptors.request.use(
        BaseAPIClient.interceptors.request.onFulfilled,
        BaseAPIClient.interceptors.request.onRejected
      );
    }

    if (
      !isNil(BaseAPIClient.interceptors.response.onFulfilled) ||
      !isNil(BaseAPIClient.interceptors.response.onRejected)
    ) {
      result.client.interceptors.response.use(
        BaseAPIClient.interceptors.response.onFulfilled,
        BaseAPIClient.interceptors.response.onRejected
      );
    }

    const cancelTokenHelper = axios.CancelToken;
    result.cancelToken = cancelTokenHelper.source();

    const config: AxiosRequestConfig = {};

    config.withCredentials = clientConfig.withCredentials ?? true;

    config.headers = this.prepareRequestHeaders(clientConfig.headers ?? {}, config.withCredentials);
    config.params = this.prepareRequestParams({});

    config.cancelToken = result.cancelToken.token;

    result.config = config;

    return result;
  };

  static prepareResponse<R extends APIResponse>(request: APIRequest, result?: Error | APIResponse | AxiosResponse): R {
    console.assert(request);

    const response: APIResponse = {
      request,
      date: new Date(),
      success: true,
      canceled: false,
      status: 0,
      statusText: '',
    };

    if (isNil(result)) return response as R;

    const httpResponse = result as AxiosResponse;

    response.success = httpResponse.status >= 200 && httpResponse.status < 300;

    if (result instanceof Error) {
      const error = result as Error;

      let errorCode = -1;
      let errorMessage = error.message;

      const errorResponse = (error as any).response;
      if (errorResponse) {
        if (!isNil(errorResponse.status)) errorCode = errorResponse.status;

        if (!isNil(errorResponse.statusText)) errorMessage = errorResponse.statusText;
      }

      response.success = false;
      response.canceled = axios.isCancel(error);

      response.status = errorCode;
      response.statusText = errorMessage;

      if (!response.success) {
        response.error = new AppError(error);
        response.error.code = errorCode;
      }
    } else {
      const apiResponse = result as APIResponse;

      response.canceled = apiResponse.canceled;

      response.status = apiResponse.status;
      response.statusText = apiResponse.statusText;

      response.error = apiResponse.error;
    }

    return response as R;
  }
}
