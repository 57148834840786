import React, { ReactElement, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Cookies from 'js-cookie';
import { SiteExp } from '@groma-dev/scaffold';
import { AppRoutes } from '../../../core/routes';
import { useAppStore /* , useDataStore */ } from '../../providers/StoresProvider';
import { AppConfig } from '../../../core/AppConfig';
import { FirebaseEvents } from '../../utils/Constants';
import { buildUrlWithParams } from '../../utils/WebHelper';

const AuthRouter = observer(({ children }: { children: ReactElement }) => {
  const { firebase } = useAppStore();
  const { pathname, search } = useLocation();
  const { Authentication, Server, Domains, Metrics } = AppConfig.Settings;
  const { enabled: requireAuthentication, cookie } = Authentication;
  const { name: authCookieName } = cookie;
  const { enabled: protectionEnabled, protectionKey, cookieName: appCookieName } = Server.passwordProtection;
  const authCookiePresent = Cookies.get(authCookieName);
  const appPasswordMatch = Cookies.get(appCookieName) === protectionKey;
  // const dataStore = useDataStore();
  // const { userState } = dataStore;

  const [currentLocation, setCurrentLocation] = useState<string>('');
  const [initialized, setInitialized] = useState<boolean>(false);

  const firePageViewEvent = () => {
    if (Metrics.Firebase.enabled) {
      if (currentLocation !== pathname) {
        setCurrentLocation(pathname);
        firebase.fireEvent(FirebaseEvents.PAGE_VIEW, { path: location.pathname });
      }
    }
  };

  // App Password Protected
  if (protectionEnabled && !appPasswordMatch && pathname !== AppRoutes.Auth) {
    const returnUrl = encodeURIComponent(`${pathname}${search}`);

    return <Navigate to={buildUrlWithParams(`${AppRoutes.Auth}?return_url=${returnUrl}`)} replace />;
  } else if (protectionEnabled && appPasswordMatch && pathname === AppRoutes.Auth) {
    return <Navigate to={buildUrlWithParams(AppRoutes.Home)} replace />;
    // App requires authentication
  }

  if ((protectionEnabled && appPasswordMatch) || !protectionEnabled) {
    if (requireAuthentication && !authCookiePresent) {
      const returnUrl = encodeURIComponent(`${pathname}${search}`);
      // TODO: change site experience
      window.location.href = `${Domains.id.baseUrl}/signin?return_url=${returnUrl}&site_exp=${SiteExp.LANDING}`;
      return null;
    }
  }

  // TODO: Add auth at a later point (need ID app in production for this to be used)
  // if (authCookiePresent && !userState.user && !userState.fetchingUser && !initialized) {
  //   dataStore.fetchUser();
  // }

  firePageViewEvent();

  if (!initialized) setInitialized(true);

  return children;
});

export default AuthRouter;
