import React from 'react';
import { observer } from 'mobx-react-lite';
import { classNames, getTestIdAttribute } from '@groma-dev/blueprint';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../../../core/routes';
import styles from './Footnotes.module.scss';

const Footnotes = observer(() => {
  const { pathname } = useLocation();

  const handleFootnoteItems = () => {
    switch (pathname) {
      case AppRoutes.Home:
        return (
          <>
            <li {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.footnotes.home.1"
                description="Source for four million of these buildings in the country footnote"
                defaultMessage="U.S. Census Bureau, American Housing Survey (2021)"
              />
            </li>
            <li {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.footnotes.home.2"
                description="Source for 45-50% at acquisition to ~30-35% in the Operate Technology Stack footnote"
                defaultMessage="Groma Q2 2023 Groma MUR NOI Performance Case Study, available upon request"
              />
            </li>
            <li {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.footnotes.home.3"
                description="Source for stats on stabilized properties footnote"
                defaultMessage="GromaREIT Q2 2023 Report, available upon request"
              />
            </li>
            <li {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.footnotes.home.4"
                description="Source for achieving ~6% annual distributions and an estimated ~22% IRR footnote"
                defaultMessage="Groma Boston Fund I internal valuation for Q4 2022, available upon request"
              />
            </li>
          </>
        );
      case AppRoutes.Partnerships:
        return (
          <>
            <li {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.footnotes.partnerships.1"
                description="Source for  footnote"
                defaultMessage="Groma Boston Fund I Performance"
              />
            </li>
            <li {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.footnotes.partnerships.2"
                description="Source for  footnote"
                defaultMessage="Chandan Economics Q4 2022"
              />
            </li>
            <li {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.footnotes.partnerships.3"
                description="Source for footnote"
                defaultMessage="Arbor & Chandon Economics Q1 2021 SFR Whitepaper"
              />
            </li>
            <li {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.footnotes.partnerships.4"
                description="Source for footnote"
                defaultMessage="Groma Boston Fund I internal valuation for Q4 2022, available upon request"
              />
            </li>
            <li {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.footnotes.partnerships.5"
                description="Source for footnote"
                defaultMessage="U.S. Census Bureau, American Housing Survey. (2019)"
              />
            </li>
          </>
        );
      default:
        return <></>;
    }
  };

  const handleFigureDescriptions = () => {
    switch (pathname) {
      case AppRoutes.Partnerships:
        return (
          <>
            <li id="partnerships-figure-desc-1" {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.figures.partnerships.1"
                description="Long description for figure-1 in the Partnerships page"
                defaultMessage="The bar chart shows Groma’s operating expense (OpEx) ratio compared to a DIY landlord. Groma's OpEx ratio is lower than that of a DIY landlord and continues to decrease across three years."
              />
            </li>
            <li id="partnerships-figure-desc-2" {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.figures.partnerships.2"
                description="Long description for figure-2 in the Partnerships page"
                defaultMessage={
                  // eslint-disable-next-line max-len
                  'The bar chart compares cap rates for large multifamily (buildings with 100+ units) versus small multifamily (buildings with 20 units or less). This comparison is made both nationally for the United States and for Boston, MA. Boston’s small multifamily cap rate is slightly higher than the national average, whereas Boston’s large multifamily roughly compares to that nationally.'
                }
              />
            </li>
            <li id="partnerships-figure-desc-3" {...getTestIdAttribute('footnotesListItem')}>
              <FormattedMessage
                id="footer.figures.partnerships.3"
                description="Long description for figure-3 in the Partnerships page"
                defaultMessage="The bar chart shows quarterly cap rate trends for the single family rental (SFR) space from 2011-2020. Overall, cap rates decreased from 10% in 2011 to below 6% in 2020."
              />
            </li>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div id="footnotes" className={classNames(styles.footnotesContainer)}>
      <h6>
        <FormattedMessage
          id="footer.footnotes.sources.heading"
          description="Footnotes title for list of sources"
          defaultMessage="Sources"
        />
      </h6>
      <ol className={classNames(styles.footnotesList)} {...getTestIdAttribute('footnotesList')}>
        {handleFootnoteItems()}
      </ol>
      {pathname === AppRoutes.Home && (
        <p className={classNames(styles.imageSource)}>
          <FormattedMessage
            id="footer.footnotes.images"
            description="Source note for images in the MUR section"
            defaultMessage="Properties shown in the Modern Urban Rental section are representative sample of MURs. Each shown is wholly or partially owned by GromaREIT."
          />
        </p>
      )}
      {pathname === AppRoutes.Partnerships && (
        <>
          <h6>
            <FormattedMessage
              id="footer.footnotes.figures.heading"
              description="Footnotes title for list of figure descriptions"
              defaultMessage="Figures"
            />
          </h6>
          <ol className={classNames(styles.footnotesList)} {...getTestIdAttribute('footnotesFigList')}>
            {handleFigureDescriptions()}
          </ol>
        </>
      )}
    </div>
  );
});

export default Footnotes;
