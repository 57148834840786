import { createStyleSheet, GlobalThemeInterface, headerHeight, vh } from '@groma-dev/blueprint';

export const getAppStyles = createStyleSheet(
  (theme: GlobalThemeInterface) => ({
    '@global': {
      body: {
        backgroundColor: theme.colors.$primary400,
      },
    },
    mainContainer: {
      alignItems: 'center',
      backgroundColor: theme.colors.$background100,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      maxWidth: '100%',
      minHeight: vh(),
      minWidth: '100%',
      width: '100%',
    },
    appHeader: {
      boxShadow: 'none',
    },
    mainWrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: '100%',
      width: '100%',
    },
    listings: {
      minHeight: `calc(${vh()} - ${headerHeight}px)`,
    },
    appFooter: {
      '& .AuxiliaryFooter-local': {
        backgroundColor: theme.colors.$primary500,
        padding: 0,
      },
    },
    hidden: {
      display: 'none',
    },
    [`@media (max-width: ${theme.breakpoints.$md}px)`]: {
      listings: {
        '&#ttRentalList': {
          '& .tt-rental-row, & .tt-filter-sort': {
            padding: theme.spacing.$s2,
          },
        },
      },
    },
  }),
  { name: 'App' }
);
