import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from '../../providers/StoresProvider';
import { AppMode } from '../../stores/AppStoreInterface';
import { getHeroStyles } from './styles/Hero.styles';

export enum HeroImagePosition {
  COVER = 'cover',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
}

export interface HeroProps {
  backgroundSrc?: string;
  backgroundSrcFallback?: string;
  imagePosition?: HeroImagePosition;
  imageOverlay?: boolean;
  children?: ReactNode;
}

const Hero = observer((props: HeroProps) => {
  const {
    backgroundSrc,
    backgroundSrcFallback,
    imagePosition = HeroImagePosition.COVER,
    imageOverlay = false,
    children,
  } = props;
  const { mode } = useAppStore().state;
  const styles = getHeroStyles();
  const backgroundImageUrl = backgroundSrc
    ? backgroundSrcFallback
      ? `url(${backgroundSrc}), url(${backgroundSrcFallback})`
      : `url(${backgroundSrc})`
    : undefined;

  const isDesktop = mode === AppMode.Desktop;

  let content;

  switch (imagePosition) {
    case HeroImagePosition.RIGHT:
      content = (
        <div className={`${styles.rowWrapper} ${!isDesktop ? styles.reverseOrder : ''}`}>
          <div className={styles.leftContentContainer}>{children}</div>
          <div className={styles.rightImageContainer} style={{ backgroundImage: backgroundImageUrl }} />
        </div>
      );
      break;
    case HeroImagePosition.LEFT:
      content = (
        <div className={styles.rowWrapper}>
          <div className={styles.leftImageContainer} style={{ backgroundImage: backgroundImageUrl }} />
          <div className={styles.rightContentContainer}>{children}</div>
        </div>
      );
      break;
    case HeroImagePosition.BOTTOM:
      content = (
        <div className={styles.columnWrapper}>
          <div className={styles.topImageContainer} style={{ backgroundImage: backgroundImageUrl }} />
          <div className={styles.bottomContentContainer}>{children}</div>
        </div>
      );
      break;
    default:
      content = (
        <div className={styles.coverImage} style={{ backgroundImage: backgroundImageUrl }}>
          {children && (
            <div className={`${styles.coverWrapper} ${imageOverlay ? styles.imageOverlay : ''}`}>
              <div className={styles.coverContentContainer}>
                <div className={styles.coverContent}>{children}</div>
              </div>
            </div>
          )}
        </div>
      );
      break;
  }
  return <div className={`${styles.mainContainer} ${!children ? styles.empty : ''}`}>{content}</div>;
});

export default Hero;
