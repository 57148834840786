import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AppLoader from './web/AppLoader';
import { AppConfig } from './core/AppConfig';
// import reportWebVitals from './reportWebVitals';

import './assets/sass/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const StrictComponent = ({ exclude, children }: { exclude?: string[]; children: React.ReactElement }) => {
  return exclude?.includes(global.window.location.pathname) ? (
    children
  ) : (
    <React.StrictMode>{children}</React.StrictMode>
  );
};

root.render(
  <StrictComponent>
    <AppLoader />
  </StrictComponent>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

const { enabled: ServiceWorkerEnabled, options } = AppConfig.Settings.ServiceWorker;

if (ServiceWorkerEnabled) {
  serviceWorkerRegistration.register({
    onUpdate: registration => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker && options.forceUpdateRefresh) {
        waitingServiceWorker.addEventListener('statechange', event => {
          const serviceWorker: ServiceWorker | null = event.target as ServiceWorker;
          if (serviceWorker?.state === 'activated') {
            window.location.reload();
          }
        });

        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    },
  });
} else serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
